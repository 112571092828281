<template lang="pug">
.Tutorial
  button(
    :class="isModeTutorial && 'buttonHelp'"
  ).button-without-styles.d-flex.justify-content-center.align-items-center.m-0.position-relative.mt-0
    img(@click="onToggleTutorialMode" src="@/assets/older_adults/helpIcon.svg" alt="helpIcon").helpIcon
    .tooltipTutorial(v-if="showTooltip" @click="onToggleTutorial")
      p.mb-0.text-center.mx-auto.p-2 {{ isModeTutorial ? "Desactivar" : "Presiona este ícono para activar el tutorial" }}
</template>

<script>
import { mapState, mapMutations } from "vuex";
import generateIds from "@/utils/tutorial_ids_2.js";

export default {
  name: "Tutorial",

  props: ["tutorial_length", "firstCharge", "showTutorialTurn"],

  data() {
    return {
      ids_to_review: {},
      createdTutorialBox: false,
      showTooltip: false,
      eventFunctions: [],
      timerToolTip: null
    };
  },

  mounted() {
    if (this.isModeTutorial) {
      this.getStarted(this.$route.path);
    }

    if (this.firstCharge && localStorage.getItem("isModeTutorial")) {
      this.showTooltip = true;
    }
  },

  computed: {
    ...mapState({
      view: state => state.olderAdult.view,
      isModeTutorial: state => state.olderAdult.isModeTutorial
    })
  },

  watch: {
    showTooltip(val) {
      if (val) {
        this.timerToolTip = setTimeout(() => {
          this.showTooltip = false;
          clearTimeout(this.timerToolTip);
        }, 7000);
      } else {
        if (this.timerToolTip) {
          clearTimeout(this.timerToolTip);
          this.timerToolTip = null;
        }
      }
    }
  },

  destroyed() {
    if (this.timerToolTip) {
      clearTimeout(this.timerToolTip);
      this.timerToolTip = null;
    }
  },

  methods: {
    ...mapMutations({
      setState: "olderAdult/setState"
    }),

    onToggleTutorialMode() {
      localStorage.setItem("isModeTutorial", !this.isModeTutorial);

      this.setState({
        key: "isModeTutorial",
        value: !this.isModeTutorial
      });
      const desactivate = !this.isModeTutorial;

      if (desactivate) {
        this.cleanTutorial();

        const $tutorialBoxes = document.querySelectorAll(".tutorialBox");
        $tutorialBoxes.forEach($tutorialBox => {
          $tutorialBox.parentNode.removeChild($tutorialBox);
        });
      } else {
        this.ids_to_review = {};
        this.createdTutorialBox = false;
        this.showTooltip = false;
        this.eventFunctions = [];

        const $tutorialBoxExistent = document.querySelector(".tutorialBox");

        if ($tutorialBoxExistent) {
          $tutorialBoxExistent.parentNode.removeChild($tutorialBoxExistent);
        }

        this.getStarted(this.$route.path);
      }
    },

    getStarted(valueIds) {
      this.createdTutorialBox = false;
      this.ids_to_review = this.selectIds(valueIds);

      if (this.ids_to_review.value) {
        this.ids_to_review.value.map((id_to_review, index) => {
          const $element_to_review_local = document.getElementById(
            id_to_review
          );

          const value = event =>
            this.eventTutorialStarted(
              event,
              $element_to_review_local,
              id_to_review,
              index
            );

          this.eventFunctions.push({
            key: id_to_review,
            value
          });

          if ($element_to_review_local)
            $element_to_review_local.addEventListener("click", value);

          if (this.view === "Atention") {
            $element_to_review_local?.click();
          } else {
            if (index === 0) {
              console.log($element_to_review_local);
              $element_to_review_local?.click();
            }
          }
        });
      }
    },

    cleanTutorial() {
      if (Object.keys(this.ids_to_review).length) {
        this.ids_to_review.value.map(id_to_review => {
          const $element_to_review_local = document.getElementById(
            id_to_review
          );
          const eventFunction = this.eventFunctions.find(
            eFuntion => eFuntion.key === id_to_review
          );

          $element_to_review_local?.removeEventListener(
            "click",
            eventFunction.value
          );
        });
      }
      this.createdTutorialBox = false;
    },

    selectIds(view) {
      const views = {
        Autenticate: {
          value: [
            "cc_number_container",
            "id_number_container",
            "phone_number_container"
          ]
        },
        Atention: {
          value: ["authorizations-container", "service_client-container"]
        },
        UploadFiles: {
          value: [
            "add-doc-container",
            "secondadd-doc-container",
            "delete-doc-container"
          ]
        },
        "video-tutorial": {
          value: ["video-tutorial"]
        }
      };

      const turnView = {
        "/turno_oa": {
          // value: [
          //   "microphone",
          //   "video_camera",
          //   "finish_video_call",
          //   "add_files",
          //   "write_message",
          //   "send_message"
          // ]
        }
      };

      if (this.showTutorialTurn) {
        return views["video-tutorial"];
      }

      return turnView[view] || views[this.view] || {};
    },

    eventTutorialStarted(event, $element_to_review_local, id_to_review, index) {
      this.showTooltip = false;

      if (
        event.target.id + "_container" === id_to_review ||
        event.target.id === id_to_review
      ) {
        const $tutorialBox = this.createTutorialBox(id_to_review, index);
        if ($tutorialBox) {
          $element_to_review_local.appendChild($tutorialBox);
        }
      }

      // if (!this.createdTutorialBox) {
      //   event.preventDefault();
      //   event.stopPropagation();
      //   return;
      // }

      // if (this.view === "Atention") {
      //   $tutorialBoxExistents
      //   if (
      //     (typeof event.target.nextSibling?.click === "function",
      //     typeof event.target.nextSibling?.focus === "function")
      //   ) {
      //     event.target.nextSibling?.click();
      //     event.target.nextSibling?.focus();
      //   }
      // }
    },

    onToggleTutorial() {
      this.showTooltip = !this.showTooltip;
    },

    createTutorialBox(id_to_review, index) {
      let renderReview = generateIds();
      renderReview = renderReview[id_to_review];
      const $tutorialBox = document.createElement("div");
      const $tutorialBoxExistent = document.querySelector(".tutorialBox");
      $tutorialBox.classList.add("tutorialBox");
      $tutorialBox.classList.add(renderReview.class);
      $tutorialBox.innerHTML = `
        <button class="svgIconClose button-without-styles">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.41406 12.4297L11.8694 2.1381" stroke="#F4F4F4" stroke-width="2.61364" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.41406 2.30078L11.8694 12.5924" stroke="#F4F4F4" stroke-width="2.61364" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
        <h5>${renderReview.title}</h5>
        <p class="text-desc">${renderReview.description}</p>
        ${
          this.view !== "Atention"
            ? `<p class="text-steps">${index + 1} de ${
                this.ids_to_review.value.length
              }</p>`
            : ""
        }
        <svg class="triangulo" width="12" height="28" viewBox="0 0 12 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9996 27.0664L11.9996 0.647485L0.238281 13.8569L11.9996 27.0664Z" fill="#081E3D"/>
        </svg>
      `;

      if ($tutorialBox.innerHTML !== $tutorialBoxExistent?.innerHTML) {
        this.createdTutorialBox = false;
        const $tutorialBoxes = document.querySelectorAll(".tutorialBox");
        if (this.view !== "Atention") {
          $tutorialBoxes.forEach($tutorialBoxLocal => {
            $tutorialBoxLocal.parentNode.removeChild($tutorialBoxLocal);
          });
        }
        $tutorialBox
          .querySelector(".svgIconClose")
          .addEventListener("click", () => {
            const $tutorialBoxes = document.querySelector(".tutorialBox");
            $tutorialBoxes.parentNode.removeChild($tutorialBoxes);
            this.onToggleTutorialMode();
            this.showTooltip = true;
          });
        return $tutorialBox;
      }

      this.createdTutorialBox = true;
      return;
    }
  }
};
</script>

<style>
.Tutorial {
  position: relative;
}

.buttonHelp {
  width: 42px;
  height: 42px;
  border-radius: 6px;
  background: #009fe3;
}

.helpIcon {
  margin-top: 7px;
}

.tooltipTutorial {
  width: 191px;
  /* height: 56px; */
  position: absolute;
  padding: 0 16px;
  border-radius: 4.84848px;
  z-index: 23;
  top: 58px;
  right: -10px;
  left: auto;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  background: #f4f4f4;
}

.tooltipTutorial p {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #0058a2;
}

.tooltipTutorial::after {
  content: "";
  width: 24.5px;
  height: 10.91px;
  position: absolute;
  top: -8px;
  right: 20px;
  background-image: url("../../../assets/whiteTriangule.svg");
}

/* @media screen and (max-width: 780px) {
  .tooltipTutorial::after {
    right: 13px;
    
  }
} */
</style>
