<template lang="pug">
.Header.py-3.px-5
  .Header__content.d-flex.justify-content-between.align-items-center
    button(
      @click="onGoBack"
      v-if="view !== 'Home' && showArrow"
    ).button-without-styles.Header__back_button.d-flex.align-items-center
      span.mr-2.d-block
        img(src="@/assets/older_adults/backArrow.svg" alt="backArrow").d-block
      p.mb-0.Header__text Regresar
    button(
      @click="onGoBack"
      v-else
    ).button-without-styles.Header__back_button.d-flex.align-items-center.opacity-0
      span.mr-2.d-block
        img(src="@/assets/older_adults/backArrow.svg" alt="backArrow").d-block
      p.mb-0.Header__text Regresar
    img(:src="Logo" @click="onGoToHome").Header__client_logo
    Tutorial(v-if="showTutorialTurn" :firstCharge="firstCharge" :showTutorialTurn="showTutorialTurn").mb-0.pl-5
    Tutorial(v-if="viewDisplayed && view !== 'Home'" :firstCharge="firstCharge").mb-0.pl-5
    Tutorial(v-else-if="showTutorial && view !== 'Home'" :firstCharge="firstCharge").mb-0.pl-5
    img(:src="Logo" @click="onGoToHome" v-else v-show="!showTutorialTurn").Header__client_logo.opacity-0
</template>

<script>
import { mapActions, mapState } from "vuex";

import Tutorial from "./Tutorial.vue";

export default {
  name: "Header",

  data() {
    return {
      viewDisplayed: false,
      firstCharge: false
    };
  },

  props: {
    showTutorial: Boolean,
    showArrow: {
      type: Boolean,
      default: true
    },
    showTutorialTurn: Boolean
  },

  components: {
    Tutorial
  },

  created() {
    this.firstCharge = true;
  },

  watch: {
    view(value) {
      if (value) {
        this.viewDisplayed = false;
        const timeout = setTimeout(() => {
          this.viewDisplayed = true;
          clearTimeout(timeout);
        }, 10);
      }
    }
  },

  computed: {
    ...mapState({
      env: state => state.env,
      view: state => state.olderAdult.view
    }),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO_OA || this.LogoColmedica;
    }
  },

  methods: {
    ...mapActions({
      goBack: "olderAdult/goBack",
      goToView: "olderAdult/goToView"
    }),

    onGoBack() {
      if (this.$route.path === "/turno_oa") {
        this.$emit("go_back", false);
      } else {
        this.goBack();
      }
    },

    onGoToHome() {
      this.goToView({ view: "Home" });
    }
  }
};
</script>

<style lang="scss">
.Header {
  background-color: var(--color-primary);
}

.Header__back_button {
  color: var(--color-white);
}

.Header__text {
  font-weight: 500;
  font-size: 19px;
}

.Header__client_logo {
  max-width: 148px;
  max-height: 39px;
  height: auto;
}

.Header__content {
  max-width: 1078px;
  margin: auto;
}

.opacity-0 {
  opacity: 0;
}
</style>
