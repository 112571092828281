<template lang="pug">
  ul.list-group
    li.list-group-item Ancho de banda estimado: {{ estimatedBandwidth }} Kbps.
    li.list-group-item Ancho de banda requerido: {{ requiredBandwidth }} Kbps.
    li.list-group-item Estado de la conexión: {{ connectionIsGood ? "Buena" : "Mala" }}.
    li.list-group-item Tasa de bits: {{ bitrateKbps }} Kbps.
    li.list-group-item Paquetes por segundo: {{ packetsPerSecond }}.
    li.list-group-item Fallos por segundo: {{ nackCountPerSecond }}.
    li.list-group-item Sugerencia de detener video: {{ videoStopSuggestion ? "Si" : "No" }}.
</template>
<script>
import { mapState } from "vuex";
import { GTMService as GM } from "../../../helpers/gtm.service";

export default {
  name: "ModalListStatus",

  data: () => ({
    timer: null,
    listsValues: []
  }),

  created() {
    this.timer = setInterval(() => {
      GM.event({
        name: "GTMVideoMeetBandwidth",
        eventCategory: "Meeting",
        eventAction: "Bandwidth_Measure",
        eventValue: this.bitratePondered
      });
      this.listsValues = [];
    }, 30000);
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  watch: {
    bitrateKbps(val) {
      this.listsValues.push(val);
    }
  },

  computed: {
    ...mapState({
      bitrateKbps: state => state.meeting.bitrateKbps,
      packetsPerSecond: state => state.meeting.packetsPerSecond,
      nackCountPerSecond: state => state.meeting.nackCountPerSecond,
      videoStopSuggestion: state => state.meeting.videoStopSuggestion,
      connectionIsGood: state => state.meeting.connectionIsGood,
      estimatedBandwidth: state => state.meeting.estimatedBandwidth,
      requiredBandwidth: state => state.meeting.requiredBandwidth
    }),

    bitratePondered() {
      return Math.ceil(
        this.listsValues.length
          ? this.listsValues.reduce((prev, curr) => prev + curr, 0) /
              this.listsValues.length
          : this.bitrateKbps
      );
    }
  }
};
</script>
