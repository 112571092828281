<template lang="pug">
.TutorialVideos.text-center
  h2.TitleSection.mt-4.pt-2.mb-0 Conoce más sobre Colmédica
  .containerVideos
    .position-relative.glider.mt-4
      .position-relative(v-for="(video, index) in videos")
        video(
          :src="video"
          playsinline
          controls
          loop
          muted="false"
          :id="index"
        ).VideoBordered.mt-3.pt-1
        button.button-without-styles.fullScreenButton(@click="() => goToFullScreen(index)")
          img(src="@/assets/older_adults/fullScreen.svg" alt="fullScreen")
        video(
          :src="video"
          playsinline
          controls
          loop
          muted="false"
          :id="index + 'videoToDisplay'"
        ).mt-3.pt-1.VideoBorderedFullScreen
    .glider-prev
      svg(width='16' height='9' viewbox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg')
        path(d='M8.5126 8.06871L8.51246 8.06883C8.39388 8.17361 8.23941 8.23001 8.08116 8.22955M8.5126 8.06871L15.5486 0.994141C15.49 0.935725 15.4203 0.889527 15.3439 0.857661C15.2675 0.8258 15.1855 0.808735 15.1027 0.807163C15.0199 0.805592 14.9373 0.819535 14.8597 0.848425C14.7828 0.877076 14.7119 0.920017 14.6513 0.975338L8.08132 6.79031L1.51263 0.97678C1.45297 0.920037 1.38258 0.875597 1.30584 0.845474C1.22763 0.814776 1.14403 0.799409 1.05997 0.800017C0.975898 0.800626 0.892535 0.817202 0.814792 0.849049C0.737035 0.880902 0.666104 0.927549 0.606618 0.986795C0.547104 1.04607 0.500251 1.11678 0.469584 1.195C0.438902 1.27326 0.425244 1.35697 0.429745 1.44094C0.434246 1.52489 0.456771 1.60667 0.495476 1.68126C0.533464 1.75447 0.586096 1.81899 0.649669 1.8715L7.64949 8.06766C7.64957 8.06773 7.64964 8.06779 7.64971 8.06786C7.76819 8.173 7.92273 8.22975 8.08116 8.22955M8.5126 8.06871L15.5142 1.87096C15.5765 1.81752 15.6277 1.75238 15.6642 1.67887C15.701 1.60471 15.722 1.52384 15.7255 1.44107L15.5256 1.43273L8.5126 8.06871ZM8.08116 8.22955C8.08096 8.22955 8.08076 8.22955 8.08057 8.22955L8.08132 8.02955L8.08175 8.22955C8.08155 8.22955 8.08136 8.22955 8.08116 8.22955Z' fill='#d93535' stroke='#d93535' stroke-width='0.4')
    .glider-next
      svg(width='16' height='9' viewbox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg')
        path(d='M8.5126 8.06871L8.51246 8.06883C8.39388 8.17361 8.23941 8.23001 8.08116 8.22955M8.5126 8.06871L15.5486 0.994141C15.49 0.935725 15.4203 0.889527 15.3439 0.857661C15.2675 0.8258 15.1855 0.808735 15.1027 0.807163C15.0199 0.805592 14.9373 0.819535 14.8597 0.848425C14.7828 0.877076 14.7119 0.920017 14.6513 0.975338L8.08132 6.79031L1.51263 0.97678C1.45297 0.920037 1.38258 0.875597 1.30584 0.845474C1.22763 0.814776 1.14403 0.799409 1.05997 0.800017C0.975898 0.800626 0.892535 0.817202 0.814792 0.849049C0.737035 0.880902 0.666104 0.927549 0.606618 0.986795C0.547104 1.04607 0.500251 1.11678 0.469584 1.195C0.438902 1.27326 0.425244 1.35697 0.429745 1.44094C0.434246 1.52489 0.456771 1.60667 0.495476 1.68126C0.533464 1.75447 0.586096 1.81899 0.649669 1.8715L7.64949 8.06766C7.64957 8.06773 7.64964 8.06779 7.64971 8.06786C7.76819 8.173 7.92273 8.22975 8.08116 8.22955M8.5126 8.06871L15.5142 1.87096C15.5765 1.81752 15.6277 1.75238 15.6642 1.67887C15.701 1.60471 15.722 1.52384 15.7255 1.44107L15.5256 1.43273L8.5126 8.06871ZM8.08116 8.22955C8.08096 8.22955 8.08076 8.22955 8.08057 8.22955L8.08132 8.02955L8.08175 8.22955C8.08155 8.22955 8.08136 8.22955 8.08116 8.22955Z' fill='#d93535' stroke='#d93535' stroke-width='0.4')
    .pt-3.pb-3
      #dots
  .boxAttendee
    h4.text-center.AssignmentTextAttendee.mb-0.mt-1 Tu turno asignado es
    h3.text-center.font-weight-bold.ImportantAsignment.mb-1 {{ assignTurn }}
    h5.mt-1.text-center.mb-1.AssignmentTextAttendee(v-if="typeofCond") Posición en la fila: 
    h5.text-middle.font-larger.ImportantAsignment(v-if="typeofCond") {{ typeof turnsInFront == "number" ? turnsInFront + 1 : "1" }}
</template>

<script>
import interact from "interactjs";

import Header from "./../older_adult/components/Header";

export default {
  name: "TutorialVideos",

  components: {
    Header
  },

  data() {
    return {
      observer: null,
      videos: [
        "https://bsix.assets.gobrilliant.com/colmedica/oficinavirtual-videos/tiktokparanodeportistass_1.mp4",
        "https://bsix.assets.gobrilliant.com/colmedica/oficinavirtual-videos/tiktokritmodesuenoo_2.mp4",
        "https://bsix.assets.gobrilliant.com/colmedica/oficinavirtual-videos/tiktokbruxissmo_3.mp4",
        "https://bsix.assets.gobrilliant.com/colmedica/oficinavirtual-videos/tiktoksindromecorazonroto_4.mp4",
        "https://bsix.assets.gobrilliant.com/colmedica/oficinavirtual-videos/tiktoksingluten_5.mp4"
      ],
      generalMute: false
    };
  },

  props: ["actualTurn", "assignTurn", "initMeet", "turnsInFront"],

  destroyed() {
    document.querySelectorAll("video").forEach($video => {
      $video.pause();
    });

    // document.exitFullscreen();
  },

  mounted() {
    const position = { x: 0, y: 0 };
    const boxAttendee = interact(".boxAttendee");

    boxAttendee.draggable({
      listeners: {
        move(event) {
          position.x += event.dx;
          position.y += event.dy;

          event.target.style.transform = `translate(${position.x}px, ${position.y}px)`;
        }
      }
    });

    new window.Glider(document.querySelector(".glider"), {
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: false,
      dots: "#dots",
      arrows: {
        prev: ".glider-prev",
        next: ".glider-next"
      }
    });
    const options = {
      threshold: 1.0
    };

    this.observer = new IntersectionObserver(this.handlerObserver, options);

    /* eslint-disable */
    [...document.querySelectorAll(".VideoBordered")].map($videoBordered => {
      $videoBordered.addEventListener("volumechange", event => {
        this.generalMute = $videoBordered;
      }, false);
      this.observer.observe($videoBordered);
    });
    /* eslint-enable */

    const $videoBordereds = [...document.querySelectorAll(".VideoBordered")];

    $videoBordereds.forEach($videoBordered => {
      $videoBordered.addEventListener("webkitfullscreenchange", event => {
        event.preventDefault();
        $videoBordered.webkitExitFullScreen();
      });
    });
  },

  computed: {
    typeofCond() {
      return (
        // eslint-disable-next-line valid-typeof
        typeof turnsInFront != "null" || typeof turnsInFront != "undefined"
      );
    }
  },

  watch: {
    initMeet(val) {
      if (val) {
        [...document.querySelectorAll(".VideoBordered")].map($videoBordered => {
          $videoBordered.pause();
          $videoBordered.muted = true;
        });
        [...document.querySelectorAll(".VideoBorderedFullScreen")].map(
          $videoBordered => {
            $videoBordered.pause();
            $videoBordered.muted = true;
          }
        );
        document.exitFullscreen();
      }
    },
    generalMute(el) {
      if (el.muted) {
        [...document.querySelectorAll(".VideoBordered")].map($videoBordered => {
          $videoBordered.muted = true;
        });
      }
    }
  },

  methods: {
    handlerObserver(entries) {
      //- one element maybe means is a full screen option
      const fullscreenMode = document.fullscreenElement;
      if (fullscreenMode) return;

      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          entry.target.pause();
        } else {
          [...document.querySelectorAll(".VideoBordered")].map(
            $videoBordered => {
              $videoBordered.pause();
              $videoBordered.muted = true;
            }
          );
          entry.target.currentTime = "0";
          entry.target.muted = false;
          entry.target.play();
        }
      });
    },

    shadowVideoChange() {
      this.shadowVideo = true;
      const timer = setTimeout(() => {
        this.shadowVideo = false;
        clearTimeout(timer);
      }, 1500);
    },

    async goToFullScreen(videoId) {
      let $originalVideo = document.getElementById(videoId);
      let $video = document.getElementById(videoId + "videoToDisplay");

      $video.currentTime = $originalVideo.currentTime;

      $video.className = "";

      $originalVideo.pause();
      await $video.requestFullscreen();
      $video.play();
      $video.muted = false;

      $video.addEventListener("fullscreenchange", () => {
        if (!document.fullscreenElement) {
          $video.pause();
          $video.muted = true;
          $originalVideo.currentTime = $video.currentTime;
          $originalVideo.play();
          $video.className = "VideoBorderedFullScreen";
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.textTurner {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;

  /* RESPUESTAS AND ACCENT */

  color: #081e3d;
}

.containerVideos {
  height: 256px;
  position: relative;
}

.glider-prev svg {
  color: black;
  transform: rotate(90deg);
}

.glider-next svg {
  opacity: 1;
  transform: rotate(-90deg);
}

.glider-prev path,
.glider-next path {
  fill: black;
  stroke-width: 2.5;
}

.glider-next,
.glider-prev {
  top: 65%;
}

.glider {
  scroll-snap-type: x mandatory;
}

.VideoBordered {
  scroll-snap-align: start;
  width: 100%;
  max-width: 100%;
  z-index: 1;
  height: 350px !important;
  border-radius: 10px;
  position: relative;
}

.VideoBorderedFullScreen {
  display: none;
}

.VideoBorderedContainer {
  width: 523px;
  height: 350px;
}

.fullScreenButton,
.fullScreenButton img {
  width: 14px;
  height: 14px;
  position: absolute;
}

.fullScreenButton img {
  margin-top: 11px;
}

.fullScreenButton {
  width: 49px;
  height: 60px;
  right: 47px;
  bottom: 30px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxAttendee {
  width: 100%;
  max-width: 184px;
  padding: 0.5rem;

  position: fixed;
  top: 96px;
  right: 32px;
  -ms-touch-action: none;
  touch-action: none;

  background: #f4f4f4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .ImportantAsignment,
  .font-larger {
    color: #009fe3;
  }
  .font-larger {
    margin: auto;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }
  .AssignmentTextAttendee,
  .ImportantAsignment {
    font-size: 16px;
  }
  .ImportantAsignment {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }
  .ActualAttendee {
    font-size: 14px;
  }
}
</style>
