<template lang="pug">
.Modal
  .Overlay
  .ModalContent(:style="maxWidth")
    header(v-if="!hideHeader").headerModal.d-flex.align-items-center.justify-content-center
      slot(name="header")
    main(:class="hideHeader && 'pt-5 heightToFrame'").mainModal.p-4
      slot(name="main")
</template>

<script>
export default {
  name: "ModalOA",
  props: ["hideHeader", "maxWidth"]
};
</script>

<style lang="scss" scoped>
.Modal {
  position: absolute;
}

.Overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000000000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.53);
}

.ModalContent {
  width: 430px;
  position: fixed;
  z-index: 1000000005;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background: #f4f4f4;
}

.headerModal {
  height: 64.09px;
  border-radius: 20px 20px 0px 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 18.2955px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #f4f4f4;
  flex: none;
  order: 1;
  flex-grow: 0;
  background: #fb0000;
}

.mainModal {
  font-weight: 100;
  font-size: 20px;
  line-height: 23px;
  padding-right: 70px;
}

.mainModal p {
  font-size: 20px;
  color: black;
  font-weight: 600;
}

.heightToFrame {
  height: 100%;
}
</style>
